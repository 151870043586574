/**
 * VUE_APP_TITLE from .env.dev / .env.test / .env.product
 */
let BASE_URL;

const { location } = window;
let LOGIN_HREF;

switch (process.env.VUE_APP_TITLE) {
  case 'sit':
    BASE_URL = location.origin.indexOf('localhost') > -1 ? 'http://hcp360-mobile-demo.lekinsights.cn/' : (location.origin + '/');
    LOGIN_HREF = location.origin;
    break;
  case 'uat':
    BASE_URL = location.origin.indexOf('localhost') > -1 ? 'https://hcp360-sandbox.lundbecksys.cn' : (location.origin);
    LOGIN_HREF = location.origin;
    break;
  case 'prd':
  case 'prod':
  case 'production':
    BASE_URL = location.origin.indexOf('localhost') > -1 ? 'https://hcp360.lundbecksys.cn' : (location.origin);
    LOGIN_HREF = location.origin;
    break;
  case 'development':
    BASE_URL = location.origin.indexOf('localhost') > -1 ? 'https://lundbeck-kol360-mobile-dev.lekinsights.cn' : (location.origin);
    // BASE_URL = 'https://takeda-poc.lekinsights.cn/';
    LOGIN_HREF = location.origin;
    break;
  default:
    // BASE_URL = 'https://dab-hcp-microseg-dev.takeda.com.cn/';
    // BASE_URL = 'https://spider.lekinsights.cn';
    // BASE_URL = 'https://lundbeck-hcp360-dev.lekinsights.cn';
    // BASE_URL = 'https://hcp360-mobile.lundbecksys.cn';
    // BASE_URL = 'https://hcp360-mobile-sandbox.lundbecksys.cn';
    BASE_URL = 'https://lundbeck-kol360-mobile-dev.lekinsights.cn';
    // BASE_URL = location.origin.indexOf('localhost') > -1 ? 'https://lundbeck-hcp360-mobile-test.lekinsights.cn' : (location.origin);
  // BASE_URL = 'http://hcp360-mobile-demo.lekinsights.cn/';
  // BASE_URL = 'https://takeda-poc.lekinsights.cn/';
}
export { BASE_URL, LOGIN_HREF };
